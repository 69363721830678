<template>
  <div class="icoMy">
    <div class="icon" @click="fnToggleTooltip">
      <svg class="icoMySvg" width="40px" height="40px" viewBox="-22 -110 160 160" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7731 9.46057L86.2518 9.46057C91.4814 9.46057 94.6157 7.01058 94.6157 2.98388C94.6157-9.62176 78.8536-27.0143 53.5177-27.0143C28.2336-27.0143 12.4609-9.62176 12.4609 2.98388C12.4609 7.01058 15.6058 9.46057 20.7731 9.46057ZM53.5388-35.7475C64.0482-35.7475 73.0794-45.1133 73.0794-57.3241C73.0794-69.3459 64.0166-78.2986 53.5388-78.2986C43.1128-78.2986 34.0184-69.1388 34.0395-57.2205C34.0395-45.1133 43.0812-35.7475 53.5388-35.7475Z"/>
      </svg>
    </div>
    <div class="tip">
      <div class="arrow">
        <div></div>
      </div>
      <ul>
        <li><a @click="()=>{this.$router.push('/mypage/myInfo')}">마이페이지</a></li>
        <li><button @click="fnMixLogout">로그아웃</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import authMix from "../../../mixins/authMix";
import userService from "../../../service/userService";
export default {
  mixins:[authMix],
  mounted() {
    // 툴팁 닫기
    // document.addEventListener("click", e=>{
    //   if(e.target.parentNode.classList[0]!='icon' && e.target.parentNode.classList[0]!='icoMySvg'){
    //     const _tip = document.querySelector(".icoMy .tip");
    //     if(_tip && _tip.style){
    //       const display = _tip.style.display;
    //       if(display == 'block'){
    //         document.querySelector(".icoMy .tip").style.display = 'none';
    //       }
    //     }
    //   }
    // })
  },
  methods:{
    fnToggleTooltip(e){
      // const display = document.querySelector(".icoMy .tip").style.display;
      // const toggledDisplay = (display=='none' || !display) ? 'block' : 'none';
      // document.querySelector('.icoMy .tip').style.display=toggledDisplay;
    },
  }
}
</script>

<style scoped>
.icoMy svg{
  fill: #3497FD;
/*  #3497FD*/
}
.icoMy svg:hover{
  fill: white;
}
.icoMy .icon{
  width: 40px;
  height: 40px;
  border: 1px solid #3497FD80;
  border-radius: 20px;
  background-color: #3497FD1A;
  cursor: pointer;
}
.icoMy .icon:hover{
  border: 1px solid #3497FD;
  background-color: #3497FD;
}
.icoMy .arrow{
    left: 89%
}
.icoMy .tip{
  right: 0px;
}
</style>
