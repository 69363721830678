const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['mng_auth'] = require('../middleware/mng_auth.js')
middleware['mng_auth'] = middleware['mng_auth'].default || middleware['mng_auth']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['userActLoggerMiddleware'] = require('../middleware/userActLoggerMiddleware.js')
middleware['userActLoggerMiddleware'] = middleware['userActLoggerMiddleware'].default || middleware['userActLoggerMiddleware']

export default middleware
