<template>
  <div class="compNm"
       style="display: flex;"
       :style="{fontSize: $store.state.ui.view=='mobile'? '13px' : '15px', marginRight: $store.state.ui.view=='mobile'? '1px' : '20px'}"
       v-click-outside="fnClickOutside"
  >
    <div style="overflow: hidden; white-space: nowrap;" @click="fnToggleCompListToolTip" :style="{lineHeight:$store.state.ui.view=='mobile'?'2.3':'2', maxWidth:userNm.length>8&&$store.state.ui.view=='mobile'?'80px':'unset',textOverflow: userNm.length>8&&$store.state.ui.view=='mobile'?'ellipsis':'unset'}">{{userNm}}</div>
    <svg @click="handleSvgClick" width="20px" line-height="5" height="20px" viewBox="-20-130 160 120" xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg" d="M56.3576-7.93224C58.3622-7.9504 60.0732-8.68882 61.6227-10.2943L98.3233-47.8592C99.561-49.104 100.206-50.6034 100.206-52.424C100.206-56.1062 97.3025-59.0652 93.6566-59.0652C91.8802-59.0652 90.1582-58.3063 88.8479-56.9968L54.1548-21.343L58.585-21.343L23.8303-56.9968C22.5271-58.2882 20.8721-59.0652 19.0476-59.0652C15.3946-59.0652 12.4609-56.1062 12.4609-52.424C12.4609-50.6216 13.1314-49.115 14.3509-47.8521L51.0997-10.2872C52.6792-8.66355 54.3342-7.93224 56.3576-7.93224Z"/>
    </svg>
    <div class="tip">
      <div class="arrow">
        <div></div>
      </div>
      <ul>
        <li v-for="(comp,idx) in companyList" :key="idx">
          <a @click="fnChangeMyComp(comp)">{{comp.compNm}}</a>
        </li>
        <li><button @click="mixFnAddComp">+ 사업자추가</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
export default {
  mixins:[serviceMix],
  computed:{
    companyList(){
      return this.$store.state.userStore.myCompList
    },
    userNm(){
      if(this.$isLogin()){
        const myCompany = this.$store.getters["userStore/getMyComp"]
        if( myCompany ){
          return myCompany.compNm;
        } else {
          return this.$getUserInfo().userNm;
        }
      }
      return '';
    },
  },
  mounted() {
    // 툴팁 닫기
    //console.log(this.userNm.length)
    // document.addEventListener("click", e=>{
    //   // console.log(e)
    //   if(e.target.parentNode.classList[0]!='compNm'){
    //     const _tip = document.querySelector(".compNm .tip");
    //     if(_tip && _tip.style){
    //       const display = _tip.style.display;
    //       if(display == 'block'){
    //         _tip.style.display = 'none';
    //       }
    //     }
    //   }
    // })
  },
  methods:{
    handleSvgClick(e) {
      this.fnToggleCompListToolTip(e);
      e.stopPropagation();
    },
    fnClickOutside(e){
      const display = document.querySelector(".compNm .tip").style.display;
      if ( display == 'block' ) {
        this.fnToggleCompListToolTip(e);
      }
    },
    fnToggleCompListToolTip(e){
      // console.log(e)
      // 속도 및 tip의 size계산이 필요하기 때문에 css로 처리한다.
      const display = document.querySelector(".compNm .tip").style.display;
      const toggledDisplay = (display=='none'||!display) ? 'block' : 'none';
      document.querySelector(".compNm .tip").style.display = toggledDisplay;
      if(toggledDisplay == 'block'){
        const width1 = document.querySelector(".compNm").clientWidth;
        const width2 = document.querySelector(".compNm .tip").clientWidth;
        document.querySelector(".tip").style.left = ((width1-width2)/2)+'px';
      }
    },
    fnChangeMyComp(comp){
      this.$auth_SaveBizNo(comp.bizNo);
      const homeLink = this.$goHome();
      const currentPath = this.$route.path;
      if(currentPath.indexOf("/mypage")>=0){
        // this.$router.replace("/mypage/myInfo")
      }
      else if(homeLink != currentPath){
        this.$router.replace(homeLink)
      }
    }
  }
}
</script>
