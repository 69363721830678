export default (context, inject) => {
  const { $axios, store, app, route } = context;
  inject('userPageLog', (actionDesc) => {
    log('page',actionDesc);
  });

  inject('userButtonLog', (actionDesc) => {
    log('btn',actionDesc);
  });

  function log(actionType, actionDesc){
    if ( process.client ) {
      $axios.get(`/apiMngAoV3/log/user?actTp=${actionType}&actDsc=${actionDesc}`);
    }
  }
}
