import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _534b2eaa = () => interopDefault(import('../pages/chanelTalk.vue' /* webpackChunkName: "pages/chanelTalk" */))
const _1770875b = () => interopDefault(import('../pages/t_ktcb/index.vue' /* webpackChunkName: "pages/t_ktcb/index" */))
const _7ea7159d = () => interopDefault(import('../pages/t_mainbiz/index.vue' /* webpackChunkName: "pages/t_mainbiz/index" */))
const _28c91749 = () => interopDefault(import('../pages/WebToS.vue' /* webpackChunkName: "pages/WebToS" */))
const _1f1d098e = () => interopDefault(import('../pages/WebToS2.vue' /* webpackChunkName: "pages/WebToS2" */))
const _1f00da8c = () => interopDefault(import('../pages/WebToS3.vue' /* webpackChunkName: "pages/WebToS3" */))
const _1ee4ab8a = () => interopDefault(import('../pages/WebToS4.vue' /* webpackChunkName: "pages/WebToS4" */))
const _7f6b9d86 = () => interopDefault(import('../pages/app/confirm.vue' /* webpackChunkName: "pages/app/confirm" */))
const _2013a5aa = () => interopDefault(import('../pages/mng/login.vue' /* webpackChunkName: "pages/mng/login" */))
const _016b4418 = () => interopDefault(import('../pages/mypage/articleOfIncorporation/index.vue' /* webpackChunkName: "pages/mypage/articleOfIncorporation/index" */))
const _2e3ff1a0 = () => interopDefault(import('../pages/mypage/grant/index.vue' /* webpackChunkName: "pages/mypage/grant/index" */))
const _3e62f758 = () => interopDefault(import('../pages/mypage/inquiry.vue' /* webpackChunkName: "pages/mypage/inquiry" */))
const _4e889b3e = () => interopDefault(import('../pages/mypage/insured/index.vue' /* webpackChunkName: "pages/mypage/insured/index" */))
const _85cc48fe = () => interopDefault(import('../pages/mypage/labor.vue' /* webpackChunkName: "pages/mypage/labor" */))
const _76d9fd44 = () => interopDefault(import('../pages/mypage/labor/index.vue' /* webpackChunkName: "pages/mypage/labor/index" */))
const _08659972 = () => interopDefault(import('../pages/mypage/labor/annual-leave.vue' /* webpackChunkName: "pages/mypage/labor/annual-leave" */))
const _5296c801 = () => interopDefault(import('../pages/mypage/labor/AnnualLeavePg.vue' /* webpackChunkName: "pages/mypage/labor/AnnualLeavePg" */))
const _7d48e990 = () => interopDefault(import('../pages/mypage/labor/contract.vue' /* webpackChunkName: "pages/mypage/labor/contract" */))
const _4c0cfe47 = () => interopDefault(import('../pages/mypage/labor/ContractPg.vue' /* webpackChunkName: "pages/mypage/labor/ContractPg" */))
const _764a7864 = () => interopDefault(import('../pages/mypage/labor/EmploysPg.vue' /* webpackChunkName: "pages/mypage/labor/EmploysPg" */))
const _20265fd1 = () => interopDefault(import('../pages/mypage/labor/on-duty-pay.vue' /* webpackChunkName: "pages/mypage/labor/on-duty-pay" */))
const _1eb48d3c = () => interopDefault(import('../pages/mypage/labor/OnDutyPayPg.vue' /* webpackChunkName: "pages/mypage/labor/OnDutyPayPg" */))
const _602c7cd2 = () => interopDefault(import('../pages/mypage/labor/payroll.vue' /* webpackChunkName: "pages/mypage/labor/payroll" */))
const _359f5764 = () => interopDefault(import('../pages/mypage/labor/PayrollPg.vue' /* webpackChunkName: "pages/mypage/labor/PayrollPg" */))
const _93465570 = () => interopDefault(import('../pages/mypage/labor/PayStub.vue' /* webpackChunkName: "pages/mypage/labor/PayStub" */))
const _3693f5b0 = () => interopDefault(import('../pages/mypage/labor/PayStub__.vue' /* webpackChunkName: "pages/mypage/labor/PayStub__" */))
const _0b778af2 = () => interopDefault(import('../pages/mypage/loan/index.vue' /* webpackChunkName: "pages/mypage/loan/index" */))
const _11a8743c = () => interopDefault(import('../pages/mypage/myInfo/index.vue' /* webpackChunkName: "pages/mypage/myInfo/index" */))
const _1008a7e3 = () => interopDefault(import('../pages/mypage/support/index.vue' /* webpackChunkName: "pages/mypage/support/index" */))
const _8a8ced82 = () => interopDefault(import('../pages/mypage/tax/index.vue' /* webpackChunkName: "pages/mypage/tax/index" */))
const _23418a34 = () => interopDefault(import('../pages/services/confirm.vue' /* webpackChunkName: "pages/services/confirm" */))
const _25f6093c = () => interopDefault(import('../pages/services/contactInfo.vue' /* webpackChunkName: "pages/services/contactInfo" */))
const _22857ae3 = () => interopDefault(import('../pages/services/contractBizInfo.vue' /* webpackChunkName: "pages/services/contractBizInfo" */))
const _04900cea = () => interopDefault(import('../pages/services/inputBizConfirm.vue' /* webpackChunkName: "pages/services/inputBizConfirm" */))
const _6fe5a3c6 = () => interopDefault(import('../pages/services/inputBizNo.vue' /* webpackChunkName: "pages/services/inputBizNo" */))
const _13f9f0fc = () => interopDefault(import('../pages/services/serviceComplete.vue' /* webpackChunkName: "pages/services/serviceComplete" */))
const _25246bf6 = () => interopDefault(import('../pages/services/serviceSelect.vue' /* webpackChunkName: "pages/services/serviceSelect" */))
const _608d5b70 = () => interopDefault(import('../pages/services/socialLogin.vue' /* webpackChunkName: "pages/services/socialLogin" */))
const _60bf6c06 = () => interopDefault(import('../pages/services/support/index.vue' /* webpackChunkName: "pages/services/support/index" */))
const _9aa37f46 = () => interopDefault(import('../pages/t_mainbiz/support/index.vue' /* webpackChunkName: "pages/t_mainbiz/support/index" */))
const _29fce8dc = () => interopDefault(import('../pages/display/print/PayStub.vue' /* webpackChunkName: "pages/display/print/PayStub" */))
const _0bc6a5cb = () => interopDefault(import('../pages/mypage/loan/docs.vue' /* webpackChunkName: "pages/mypage/loan/docs" */))
const _17ce5e6c = () => interopDefault(import('../pages/mypage/loan/skillCredit.vue' /* webpackChunkName: "pages/mypage/loan/skillCredit" */))
const _4cc45eaa = () => interopDefault(import('../pages/mypage/myInfo/confirm.vue' /* webpackChunkName: "pages/mypage/myInfo/confirm" */))
const _24319b24 = () => interopDefault(import('../pages/services/articleOfIncorp/print.vue' /* webpackChunkName: "pages/services/articleOfIncorp/print" */))
const _d60111e4 = () => interopDefault(import('../pages/services/employ/complete.vue' /* webpackChunkName: "pages/services/employ/complete" */))
const _966fa77a = () => interopDefault(import('../pages/services/employ/corpCert.vue' /* webpackChunkName: "pages/services/employ/corpCert" */))
const _6d981744 = () => interopDefault(import('../pages/services/employ/indCert.vue' /* webpackChunkName: "pages/services/employ/indCert" */))
const _005b0c30 = () => interopDefault(import('../pages/services/employ/indInfo.vue' /* webpackChunkName: "pages/services/employ/indInfo" */))
const _3016e732 = () => interopDefault(import('../pages/services/employ/intro.vue' /* webpackChunkName: "pages/services/employ/intro" */))
const _21195b12 = () => interopDefault(import('../pages/services/employ/loading.vue' /* webpackChunkName: "pages/services/employ/loading" */))
const _416af0c9 = () => interopDefault(import('../pages/services/employ/report.vue' /* webpackChunkName: "pages/services/employ/report" */))
const _a325f320 = () => interopDefault(import('../pages/services/employ/research.vue' /* webpackChunkName: "pages/services/employ/research" */))
const _a792719c = () => interopDefault(import('../pages/services/employ/result.vue' /* webpackChunkName: "pages/services/employ/result" */))
const _ce572438 = () => interopDefault(import('../pages/services/insurance/checkresult.vue' /* webpackChunkName: "pages/services/insurance/checkresult" */))
const _a88b2bec = () => interopDefault(import('../pages/services/insurance/complete.vue' /* webpackChunkName: "pages/services/insurance/complete" */))
const _68f9c182 = () => interopDefault(import('../pages/services/insurance/corpCert.vue' /* webpackChunkName: "pages/services/insurance/corpCert" */))
const _4f7b0a6c = () => interopDefault(import('../pages/services/insurance/failResult.vue' /* webpackChunkName: "pages/services/insurance/failResult" */))
const _4e10b1e2 = () => interopDefault(import('../pages/services/insurance/indCert.vue' /* webpackChunkName: "pages/services/insurance/indCert" */))
const _f6a19128 = () => interopDefault(import('../pages/services/insurance/indInfo.vue' /* webpackChunkName: "pages/services/insurance/indInfo" */))
const _3daa4a2a = () => interopDefault(import('../pages/services/insurance/intro.vue' /* webpackChunkName: "pages/services/insurance/intro" */))
const _29e4e5b3 = () => interopDefault(import('../pages/services/insurance/methodChoice.vue' /* webpackChunkName: "pages/services/insurance/methodChoice" */))
const _6efe72c5 = () => interopDefault(import('../pages/services/insurance/report.vue' /* webpackChunkName: "pages/services/insurance/report" */))
const _d15a622a = () => interopDefault(import('../pages/services/intro/insurance.vue' /* webpackChunkName: "pages/services/intro/insurance" */))
const _39759c7e = () => interopDefault(import('../pages/services/intro/labor.vue' /* webpackChunkName: "pages/services/intro/labor" */))
const _b11120c0 = () => interopDefault(import('../pages/services/intro/support.vue' /* webpackChunkName: "pages/services/intro/support" */))
const _279eb408 = () => interopDefault(import('../pages/services/intro/tax.vue' /* webpackChunkName: "pages/services/intro/tax" */))
const _d8606428 = () => interopDefault(import('../pages/services/loan/complete.vue' /* webpackChunkName: "pages/services/loan/complete" */))
const _0024b04e = () => interopDefault(import('../pages/services/loan/loading.vue' /* webpackChunkName: "pages/services/loan/loading" */))
const _a5854564 = () => interopDefault(import('../pages/services/loan/research.vue' /* webpackChunkName: "pages/services/loan/research" */))
const _436984e0 = () => interopDefault(import('../pages/services/loan/result.vue' /* webpackChunkName: "pages/services/loan/result" */))
const _fb6a38e6 = () => interopDefault(import('../pages/services/support/popular.vue' /* webpackChunkName: "pages/services/support/popular" */))
const _75f5d9b5 = () => interopDefault(import('../pages/services/tax/checkresult.vue' /* webpackChunkName: "pages/services/tax/checkresult" */))
const _46eff7d9 = () => interopDefault(import('../pages/services/tax/complete.vue' /* webpackChunkName: "pages/services/tax/complete" */))
const _66b8ad0e = () => interopDefault(import('../pages/services/tax/corpCert.vue' /* webpackChunkName: "pages/services/tax/corpCert" */))
const _741aae33 = () => interopDefault(import('../pages/services/tax/indCert.vue' /* webpackChunkName: "pages/services/tax/indCert" */))
const _aa8d9886 = () => interopDefault(import('../pages/services/tax/indInfo.vue' /* webpackChunkName: "pages/services/tax/indInfo" */))
const _5f79b77c = () => interopDefault(import('../pages/services/tax/intro.vue' /* webpackChunkName: "pages/services/tax/intro" */))
const _8ae9af58 = () => interopDefault(import('../pages/services/tax/report.vue' /* webpackChunkName: "pages/services/tax/report" */))
const _d21ce8e4 = () => interopDefault(import('../pages/services/tax/report2.vue' /* webpackChunkName: "pages/services/tax/report2" */))
const _72b5f582 = () => interopDefault(import('../pages/t_mainbiz/insurance/intro.vue' /* webpackChunkName: "pages/t_mainbiz/insurance/intro" */))
const _0d996650 = () => interopDefault(import('../pages/t_mainbiz/labor/intro.vue' /* webpackChunkName: "pages/t_mainbiz/labor/intro" */))
const _16c220f7 = () => interopDefault(import('../pages/t_mainbiz/support/intro.vue' /* webpackChunkName: "pages/t_mainbiz/support/intro" */))
const _5893615a = () => interopDefault(import('../pages/t_mainbiz/tax/intro.vue' /* webpackChunkName: "pages/t_mainbiz/tax/intro" */))
const _72fec77d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e80424ac = () => interopDefault(import('../pages/services/support/_pblancId.vue' /* webpackChunkName: "pages/services/support/_pblancId" */))
const _31a682fe = () => interopDefault(import('../pages/t_mainbiz/support/_pblancId.vue' /* webpackChunkName: "pages/t_mainbiz/support/_pblancId" */))
const _adf96f9c = () => interopDefault(import('../pages/t_ktcb/_fcCode.vue' /* webpackChunkName: "pages/t_ktcb/_fcCode" */))
const _0e3dfe00 = () => interopDefault(import('../pages/auth/_socialType/callback.vue' /* webpackChunkName: "pages/auth/_socialType/callback" */))
const _50b20b80 = () => interopDefault(import('../pages/_landing/index.vue' /* webpackChunkName: "pages/_landing/index" */))
const _0bc3fc5b = () => interopDefault(import('../pages/_landing/articleOfIncorporation/index.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/index" */))
const _11c65c00 = () => interopDefault(import('../pages/_landing/support/index.vue' /* webpackChunkName: "pages/_landing/support/index" */))
const _02dab3c0 = () => interopDefault(import('../pages/_landing/articleOfIncorporation/complete.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/complete" */))
const _57e8614b = () => interopDefault(import('../pages/_landing/articleOfIncorporation/form.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/form" */))
const _0bc4b785 = () => interopDefault(import('../pages/_landing/articleOfIncorporation/loading.vue' /* webpackChunkName: "pages/_landing/articleOfIncorporation/loading" */))
const _55c6aa65 = () => interopDefault(import('../pages/_landing/insurance/intro.vue' /* webpackChunkName: "pages/_landing/insurance/intro" */))
const _124feb3b = () => interopDefault(import('../pages/_landing/labor/intro.vue' /* webpackChunkName: "pages/_landing/labor/intro" */))
const _552b4678 = () => interopDefault(import('../pages/_landing/mng/labor/index.vue' /* webpackChunkName: "pages/_landing/mng/labor/index" */))
const _e81d7324 = () => interopDefault(import('../pages/_landing/mng/login.vue' /* webpackChunkName: "pages/_landing/mng/login" */))
const _5b30b29a = () => interopDefault(import('../pages/_landing/support/intro.vue' /* webpackChunkName: "pages/_landing/support/intro" */))
const _2b1d2a76 = () => interopDefault(import('../pages/_landing/tax/intro.vue' /* webpackChunkName: "pages/_landing/tax/intro" */))
const _dac25344 = () => interopDefault(import('../pages/_landing/welfare/complete.vue' /* webpackChunkName: "pages/_landing/welfare/complete" */))
const _4df49f2c = () => interopDefault(import('../pages/_landing/welfare/fund.vue' /* webpackChunkName: "pages/_landing/welfare/fund" */))
const _3084d8a7 = () => interopDefault(import('../pages/_landing/welfare/fundResult.vue' /* webpackChunkName: "pages/_landing/welfare/fundResult" */))
const _48e3c6a4 = () => interopDefault(import('../pages/_landing/support/_pblancId.vue' /* webpackChunkName: "pages/_landing/support/_pblancId" */))
const _356895d7 = () => interopDefault(import('../pages/_landing/_fcCode.vue' /* webpackChunkName: "pages/_landing/_fcCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chanelTalk",
    component: _534b2eaa,
    name: "chanelTalk"
  }, {
    path: "/t_ktcb",
    component: _1770875b,
    name: "t_ktcb"
  }, {
    path: "/t_mainbiz",
    component: _7ea7159d,
    name: "t_mainbiz"
  }, {
    path: "/WebToS",
    component: _28c91749,
    name: "WebToS"
  }, {
    path: "/WebToS2",
    component: _1f1d098e,
    name: "WebToS2"
  }, {
    path: "/WebToS3",
    component: _1f00da8c,
    name: "WebToS3"
  }, {
    path: "/WebToS4",
    component: _1ee4ab8a,
    name: "WebToS4"
  }, {
    path: "/app/confirm",
    component: _7f6b9d86,
    name: "app-confirm"
  }, {
    path: "/mng/login",
    component: _2013a5aa,
    name: "mng-login"
  }, {
    path: "/mypage/articleOfIncorporation",
    component: _016b4418,
    name: "mypage-articleOfIncorporation"
  }, {
    path: "/mypage/grant",
    component: _2e3ff1a0,
    name: "mypage-grant"
  }, {
    path: "/mypage/inquiry",
    component: _3e62f758,
    name: "mypage-inquiry"
  }, {
    path: "/mypage/insured",
    component: _4e889b3e,
    name: "mypage-insured"
  }, {
    path: "/mypage/labor",
    component: _85cc48fe,
    children: [{
      path: "",
      component: _76d9fd44,
      name: "mypage-labor"
    }, {
      path: "annual-leave",
      component: _08659972,
      name: "mypage-labor-annual-leave"
    }, {
      path: "AnnualLeavePg",
      component: _5296c801,
      name: "mypage-labor-AnnualLeavePg"
    }, {
      path: "contract",
      component: _7d48e990,
      name: "mypage-labor-contract"
    }, {
      path: "ContractPg",
      component: _4c0cfe47,
      name: "mypage-labor-ContractPg"
    }, {
      path: "EmploysPg",
      component: _764a7864,
      name: "mypage-labor-EmploysPg"
    }, {
      path: "on-duty-pay",
      component: _20265fd1,
      name: "mypage-labor-on-duty-pay"
    }, {
      path: "OnDutyPayPg",
      component: _1eb48d3c,
      name: "mypage-labor-OnDutyPayPg"
    }, {
      path: "payroll",
      component: _602c7cd2,
      name: "mypage-labor-payroll"
    }, {
      path: "PayrollPg",
      component: _359f5764,
      name: "mypage-labor-PayrollPg"
    }, {
      path: "PayStub",
      component: _93465570,
      name: "mypage-labor-PayStub"
    }, {
      path: "PayStub__",
      component: _3693f5b0,
      name: "mypage-labor-PayStub__"
    }]
  }, {
    path: "/mypage/loan",
    component: _0b778af2,
    name: "mypage-loan"
  }, {
    path: "/mypage/myInfo",
    component: _11a8743c,
    name: "mypage-myInfo"
  }, {
    path: "/mypage/support",
    component: _1008a7e3,
    name: "mypage-support"
  }, {
    path: "/mypage/tax",
    component: _8a8ced82,
    name: "mypage-tax"
  }, {
    path: "/services/confirm",
    component: _23418a34,
    name: "services-confirm"
  }, {
    path: "/services/contactInfo",
    component: _25f6093c,
    name: "services-contactInfo"
  }, {
    path: "/services/contractBizInfo",
    component: _22857ae3,
    name: "services-contractBizInfo"
  }, {
    path: "/services/inputBizConfirm",
    component: _04900cea,
    name: "services-inputBizConfirm"
  }, {
    path: "/services/inputBizNo",
    component: _6fe5a3c6,
    name: "services-inputBizNo"
  }, {
    path: "/services/serviceComplete",
    component: _13f9f0fc,
    name: "services-serviceComplete"
  }, {
    path: "/services/serviceSelect",
    component: _25246bf6,
    name: "services-serviceSelect"
  }, {
    path: "/services/socialLogin",
    component: _608d5b70,
    name: "services-socialLogin"
  }, {
    path: "/services/support",
    component: _60bf6c06,
    name: "services-support"
  }, {
    path: "/t_mainbiz/support",
    component: _9aa37f46,
    name: "t_mainbiz-support"
  }, {
    path: "/display/print/PayStub",
    component: _29fce8dc,
    name: "display-print-PayStub"
  }, {
    path: "/mypage/loan/docs",
    component: _0bc6a5cb,
    name: "mypage-loan-docs"
  }, {
    path: "/mypage/loan/skillCredit",
    component: _17ce5e6c,
    name: "mypage-loan-skillCredit"
  }, {
    path: "/mypage/myInfo/confirm",
    component: _4cc45eaa,
    name: "mypage-myInfo-confirm"
  }, {
    path: "/services/articleOfIncorp/print",
    component: _24319b24,
    name: "services-articleOfIncorp-print"
  }, {
    path: "/services/employ/complete",
    component: _d60111e4,
    name: "services-employ-complete"
  }, {
    path: "/services/employ/corpCert",
    component: _966fa77a,
    name: "services-employ-corpCert"
  }, {
    path: "/services/employ/indCert",
    component: _6d981744,
    name: "services-employ-indCert"
  }, {
    path: "/services/employ/indInfo",
    component: _005b0c30,
    name: "services-employ-indInfo"
  }, {
    path: "/services/employ/intro",
    component: _3016e732,
    name: "services-employ-intro"
  }, {
    path: "/services/employ/loading",
    component: _21195b12,
    name: "services-employ-loading"
  }, {
    path: "/services/employ/report",
    component: _416af0c9,
    name: "services-employ-report"
  }, {
    path: "/services/employ/research",
    component: _a325f320,
    name: "services-employ-research"
  }, {
    path: "/services/employ/result",
    component: _a792719c,
    name: "services-employ-result"
  }, {
    path: "/services/insurance/checkresult",
    component: _ce572438,
    name: "services-insurance-checkresult"
  }, {
    path: "/services/insurance/complete",
    component: _a88b2bec,
    name: "services-insurance-complete"
  }, {
    path: "/services/insurance/corpCert",
    component: _68f9c182,
    name: "services-insurance-corpCert"
  }, {
    path: "/services/insurance/failResult",
    component: _4f7b0a6c,
    name: "services-insurance-failResult"
  }, {
    path: "/services/insurance/indCert",
    component: _4e10b1e2,
    name: "services-insurance-indCert"
  }, {
    path: "/services/insurance/indInfo",
    component: _f6a19128,
    name: "services-insurance-indInfo"
  }, {
    path: "/services/insurance/intro",
    component: _3daa4a2a,
    name: "services-insurance-intro"
  }, {
    path: "/services/insurance/methodChoice",
    component: _29e4e5b3,
    name: "services-insurance-methodChoice"
  }, {
    path: "/services/insurance/report",
    component: _6efe72c5,
    name: "services-insurance-report"
  }, {
    path: "/services/intro/insurance",
    component: _d15a622a,
    name: "services-intro-insurance"
  }, {
    path: "/services/intro/labor",
    component: _39759c7e,
    name: "services-intro-labor"
  }, {
    path: "/services/intro/support",
    component: _b11120c0,
    name: "services-intro-support"
  }, {
    path: "/services/intro/tax",
    component: _279eb408,
    name: "services-intro-tax"
  }, {
    path: "/services/loan/complete",
    component: _d8606428,
    name: "services-loan-complete"
  }, {
    path: "/services/loan/loading",
    component: _0024b04e,
    name: "services-loan-loading"
  }, {
    path: "/services/loan/research",
    component: _a5854564,
    name: "services-loan-research"
  }, {
    path: "/services/loan/result",
    component: _436984e0,
    name: "services-loan-result"
  }, {
    path: "/services/support/popular",
    component: _fb6a38e6,
    name: "services-support-popular"
  }, {
    path: "/services/tax/checkresult",
    component: _75f5d9b5,
    name: "services-tax-checkresult"
  }, {
    path: "/services/tax/complete",
    component: _46eff7d9,
    name: "services-tax-complete"
  }, {
    path: "/services/tax/corpCert",
    component: _66b8ad0e,
    name: "services-tax-corpCert"
  }, {
    path: "/services/tax/indCert",
    component: _741aae33,
    name: "services-tax-indCert"
  }, {
    path: "/services/tax/indInfo",
    component: _aa8d9886,
    name: "services-tax-indInfo"
  }, {
    path: "/services/tax/intro",
    component: _5f79b77c,
    name: "services-tax-intro"
  }, {
    path: "/services/tax/report",
    component: _8ae9af58,
    name: "services-tax-report"
  }, {
    path: "/services/tax/report2",
    component: _d21ce8e4,
    name: "services-tax-report2"
  }, {
    path: "/t_mainbiz/insurance/intro",
    component: _72b5f582,
    name: "t_mainbiz-insurance-intro"
  }, {
    path: "/t_mainbiz/labor/intro",
    component: _0d996650,
    name: "t_mainbiz-labor-intro"
  }, {
    path: "/t_mainbiz/support/intro",
    component: _16c220f7,
    name: "t_mainbiz-support-intro"
  }, {
    path: "/t_mainbiz/tax/intro",
    component: _5893615a,
    name: "t_mainbiz-tax-intro"
  }, {
    path: "/",
    component: _72fec77d,
    name: "index"
  }, {
    path: "/services/support/:pblancId",
    component: _e80424ac,
    name: "services-support-pblancId"
  }, {
    path: "/t_mainbiz/support/:pblancId",
    component: _31a682fe,
    name: "t_mainbiz-support-pblancId"
  }, {
    path: "/t_ktcb/:fcCode",
    component: _adf96f9c,
    name: "t_ktcb-fcCode"
  }, {
    path: "/auth/:socialType?/callback",
    component: _0e3dfe00,
    name: "auth-socialType-callback"
  }, {
    path: "/:landing",
    component: _50b20b80,
    name: "landing"
  }, {
    path: "/:landing/articleOfIncorporation",
    component: _0bc3fc5b,
    name: "landing-articleOfIncorporation"
  }, {
    path: "/:landing/support",
    component: _11c65c00,
    name: "landing-support"
  }, {
    path: "/:landing/articleOfIncorporation/complete",
    component: _02dab3c0,
    name: "landing-articleOfIncorporation-complete"
  }, {
    path: "/:landing/articleOfIncorporation/form",
    component: _57e8614b,
    name: "landing-articleOfIncorporation-form"
  }, {
    path: "/:landing/articleOfIncorporation/loading",
    component: _0bc4b785,
    name: "landing-articleOfIncorporation-loading"
  }, {
    path: "/:landing/insurance/intro",
    component: _55c6aa65,
    name: "landing-insurance-intro"
  }, {
    path: "/:landing/labor/intro",
    component: _124feb3b,
    name: "landing-labor-intro"
  }, {
    path: "/:landing/mng/labor",
    component: _552b4678,
    name: "landing-mng-labor"
  }, {
    path: "/:landing/mng/login",
    component: _e81d7324,
    name: "landing-mng-login"
  }, {
    path: "/:landing/support/intro",
    component: _5b30b29a,
    name: "landing-support-intro"
  }, {
    path: "/:landing/tax/intro",
    component: _2b1d2a76,
    name: "landing-tax-intro"
  }, {
    path: "/:landing/welfare/complete",
    component: _dac25344,
    name: "landing-welfare-complete"
  }, {
    path: "/:landing/welfare/fund",
    component: _4df49f2c,
    name: "landing-welfare-fund"
  }, {
    path: "/:landing/welfare/fundResult",
    component: _3084d8a7,
    name: "landing-welfare-fundResult"
  }, {
    path: "/:landing/support/:pblancId",
    component: _48e3c6a4,
    name: "landing-support-pblancId"
  }, {
    path: "/:landing/:fcCode",
    component: _356895d7,
    name: "landing-fcCode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
